
import Vue, { PropType } from 'vue';
import TTLoader from '@/components/ui/TTLoader.vue';
import { AnswersTableHeaderItem, AnswersTableItem } from './types';

export default Vue.extend({
  name: 'AnswersTable',

  components: {
    TTLoader,
  },

  props: {
    items: {
      type: Array as PropType<AnswersTableItem[]>,
      default: () => [],
    },
    showColorIndicator: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    headers(): AnswersTableHeaderItem[] {
      return [
        {
          text: this.$t('analytics.additionalQuestions.answerOptions') as string,
          value: 'answer',
          width: '66%',
          class: 'tt-text-body-2',
          cellClass: 'tt-text-body-1 tt-light-mono-100--text d-flex flex-row align-center py-2 v-align-top',
        },
        {
          text: '%',
          value: 'percentage',
          width: '17%',
          class: 'tt-text-body-2',
          cellClass: 'tt-text-body-2 tt-light-mono-100--text py-2 v-align-top',
        },
        {
          text: this.$t('analytics.additionalQuestions.people') as string,
          value: 'peopleCount',
          width: '17%',
          class: 'tt-text-body-2',
          cellClass: 'tt-text-body-2 tt-light-mono-100--text py-2 v-align-top',
        },
      ];
    },
  },
  methods: {
    getItemStyle(color: string) {
      return `backgroundColor:${color};`;
    },
  },
});
