
import Vue from 'vue';
import {
  V1EntitiesAnalyticsFeedbackOpenQuestionAnswersQuestion,
  V1EntitiesAnalyticsFeedbackOpenQuestionAnswersAnswer,
} from '@/services/api/tsxass';
import { tsxassApi } from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import SectionTitle from '@/components/common/SectionTitle.vue';
import TTLoader from '@/components/ui/TTLoader.vue';
import NoFilteredDataStub from '@/components/analytics/widgets/NoFilteredDataStub.vue';
import SelectExpertsGroup from './SelectExpertsGroup.vue';

export default Vue.extend({
  name: 'OpenQuestionsWidget',

  components: {
    SectionTitle,
    TTLoader,
    SelectExpertsGroup,
    NoFilteredDataStub,
  },

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      chartData: {
        questions: [] as V1EntitiesAnalyticsFeedbackOpenQuestionAnswersQuestion[],
        answers: [] as V1EntitiesAnalyticsFeedbackOpenQuestionAnswersAnswer[],
        state: {
          belowAnonymityThreshold: false,
        },
      },
      loading: false,
      selectedGroupId: null as null | number,
      selectedQuestionIndex: 0,
    };
  },

  computed: {
    selectedQuestion(): V1EntitiesAnalyticsFeedbackOpenQuestionAnswersQuestion | null {
      return this.chartData.questions[this.selectedQuestionIndex] || null;
    },
    selectedQuestionAnswers(): V1EntitiesAnalyticsFeedbackOpenQuestionAnswersAnswer[] {
      if (!this.selectedQuestion) {
        return [];
      }

      return this.chartData.answers.filter((answer) => answer.questionId === this.selectedQuestion?.id);
    },
    belowAnonymityThreshold() {
      return !this.chartData.state?.display && this.chartData.state?.belowAnonymityThreshold;
    },
  },

  watch: {
    chartData() {
      this.selectedQuestionIndex = 0;
    },
    selectedGroupId: {
      immediate: true,
      handler(newValue: number) {
        this.$emit('select-group-id', newValue);
        this.loadChartData();
      },
    },
  },

  methods: {
    async loadChartData() {
      try {
        this.loading = true;

        const { data } = await tsxassApi.getV1AnalyticsFeedbackOpenQuestionsAnswers(
          this.surveyId,
          this.selectedGroupId || undefined,
          this.userId || undefined,
        );
        this.chartData = {
          questions: data?.questions || [],
          answers: data?.answers || [],
          state: data?.state || [],
        };
        this.$emit('answers-empty', !this.chartData.answers.length);
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
