
import Vue from 'vue';
import GradeVisualizationWidget from '@/components/analytics/widgets/GradeVisualizationWidget.vue';
import TopResultsWidget from '@/components/analytics/widgets/TopResultsWidget.vue';
import BaseCompetenciesHeatMap from '@/components/analytics/widgets/BaseCompetenciesHeatMap.vue';
import { VRow } from 'vuetify/lib/components';
import NoAnalyticsTabDataStub from '@/components/analytics/widgets/NoAnalyticsTabDataStub.vue';

export default Vue.extend({
  name: 'TabBaseCompetencies',

  components: {
    GradeVisualizationWidget,
    TopResultsWidget,
    BaseCompetenciesHeatMap,
    VRow,
    NoAnalyticsTabDataStub,
  },

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isJoharyEmpty: true,
      isAverageCountEmpty: false,
    };
  },
});
