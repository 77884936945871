
import Vue from 'vue';
import { tsxassApi } from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import TTLoader from '@/components/ui/TTLoader.vue';

interface GroupItem {
  id: string,
  name: string,
}

export default Vue.extend({
  name: 'CompetenciesCommentsDialog',
  components: { TTLoader },

  props: {
    questionId: {
      type: [Number, String],
      required: true,
    },
    questionName: {
      type: String,
      default: '',
    },
    groupName: {
      type: String,
      default: '',
    },
    userId: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      selectedGroup: 'all',
      groups: [] as GroupItem[],
      comments: [],
    };
  },

  computed: {
    surveyId(): number {
      return Number(this.$route.params.surveyId) || 0;
    },
    computedGroups(): GroupItem[] {
      const DEFAULT_CHOICE = {
        id: 'all',
        name: this.$t('analytics.baseCompetencies.commentsDialog.allExperts'),
      };

      return [DEFAULT_CHOICE, ...this.groups];
    },
    computedComments() {
      const notEmptyComments = this.comments.filter((comment) => comment.text.length > 0);
      if (this.selectedGroup === 'all') return notEmptyComments;

      return notEmptyComments.filter((comment) => comment.groupId === this.selectedGroup);
    },
    isShowDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        this.$emit('update:show', value);
      },
    },
  },
  watch: {
    isShowDialog(value) {
      if (value) {
        this.loadComments();
      } else {
        this.groups = [];
        this.comments = [];
        this.selectedGroup = 'all';
      }
    },
  },
  methods: {
    async loadComments() {
      try {
        this.loading = true;
        const { data: { groups, comments } } = await tsxassApi.getV1AnalyticsFeedbackGeneralQuestionsComments(
          this.surveyId,
          this.questionId || undefined,
          this.userId || undefined,
        );
        this.groups = groups;
        this.comments = comments;
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
