
import Vue from 'vue';
import DestructorDynamics from '@/components/analytics/widgets/DestructorDynamics.vue';
import SurveyDestructors from '@/components/analytics/widgets/SurveyDestructors.vue';
import NoAnalyticsTabDataStub from '@/components/analytics/widgets/NoAnalyticsTabDataStub.vue';

export default Vue.extend({
  name: 'TabDestructors',

  components: {
    DestructorDynamics,
    SurveyDestructors,
    NoAnalyticsTabDataStub,
  },

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isDestructorsDynamicsEmpty: false,
    };
  },
});
