
import Vue, { PropType } from 'vue';
import { V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestion } from '@/services/api/tsxass';
import ApportionmentChart from '@/components/analytics/charts/ApportionmentChart.vue';
import { ApportionmentChartValue } from '@/components/analytics/charts/types';
import AnswersTable from '@/components/analytics/charts/AnswersTable.vue';
import TTLoader from '@/components/ui/TTLoader.vue';
import NoFilteredDataStub from '@/components/analytics/widgets/NoFilteredDataStub.vue';
import { AnswerAliases } from '@/constants/questionScaleAnswers';
import { getScoreIconByValue, getScoreIconByName } from '@/helpers/getScoreIcon';

interface TableItem {
  id: string;
  label: string;
  percentage: string;
  value: number;
  color: string;
  answer: string;
  peopleCount: number;
}

export default Vue.extend({
  name: 'AnswersApportionmentWidget',

  components: {
    ApportionmentChart,
    AnswersTable,
    TTLoader,
    NoFilteredDataStub,
  },

  props: {
    question: {
      type: Object as PropType<V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestion>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    minRating: {
      type: Number,
      default: 1,
    },
    maxRating: {
      type: Number,
      default: 5,
    },
  },

  computed: {
    averageRatingValues(): ApportionmentChartValue[] {
      return [
        {
          id: 'average',
          value: this.question.average - this.minRating,
          color: '#039be5',
        },
        {
          id: 'offset-right',
          value: this.maxRating - this.question.average,
          color: '#F5F7F8',
        },
      ];
    },
    belowAnonymityThreshold() {
      return !this.question.state?.display && this.question.state?.belowAnonymityThreshold;
    },
    tableItems(): TableItem[] {
      const totalCount = this.question.answers.reduce((acc, answer) => acc + answer.count, 0);
      return this.question.answers.map((answer, answerIdx, answers) => {
        const percentage = `${Math.round((answer.count / totalCount) * 100)}%`;
        // @ts-ignore
        const icon = getScoreIconByName(answer.iconName as AnswerAliases);
        return {
          id: answer.id,
          percentage,
          label: percentage,
          value: answer.count,
          color: (icon || getScoreIconByValue(answerIdx + 1, 1, answers.length + 1)).color,
          answer: answer.name,
          peopleCount: answer.count,
        };
      });
    },
  },
});
