
import Vue from 'vue';
import NoAnalyticsTabDataStub from '@/components/analytics/widgets/NoAnalyticsTabDataStub.vue';
import SelectExpertsGroup from '@/components/analytics/widgets/SelectExpertsGroup.vue';
import {
  V1EntitiesAnalyticsFeedbackJohariItem,
  V1EntitiesAnalyticsFeedbackAverageRate,
} from '@/services/api/tsxass';
import { tsxassApi } from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import AverageRatingWidget from '@/components/analytics/widgets/AverageRatingWidget.vue';
import NpsHeatMap from '@/components/analytics/widgets/NpsHeatMap.vue';

type SelectedGroup = {
  id: number;
  name: string;
  withoutSelf: boolean;
};

export default Vue.extend({
  name: 'TabNps',

  components: {
    NoAnalyticsTabDataStub,
    SelectExpertsGroup,
    AverageRatingWidget,
    NpsHeatMap,
  },

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ratingGroupSelected: null as null | SelectedGroup,
      averageRatingData: null as null | V1EntitiesAnalyticsFeedbackAverageRate,
      loadingAverage: false,
      chartData: {
        values: [] as V1EntitiesAnalyticsFeedbackJohariItem[],
        settings: {
          minValue: 1,
          maxValue: 5,
        },
      },
    };
  },
  computed: {
    isTabEmpty(): boolean {
      // TODO: вернуть условие которое было убрано для тестов
      // return this.averageRatingData?.count === 0;
      return false;
    },
    key(): string {
      return `${this.surveyId}-${this.userId}`;
    },
  },
  watch: {
    key() {
      this.loadAverageRatingData();
    },
  },
  methods: {
    onRatingGroupSelect(group: SelectedGroup) {
      if (group === this.ratingGroupSelected) {
        return;
      }
      this.ratingGroupSelected = group;
      this.loadAverageRatingData();
    },
    async loadAverageRatingData() {
      try {
        this.loadingAverage = true;

        const { data } = await tsxassApi.getV1AnalyticsFeedbackAverageRate(
          this.surveyId,
          this.ratingGroupSelected?.id || undefined,
          this.userId || undefined,
          this.ratingGroupSelected?.withoutSelf,
        );
        this.averageRatingData = data;
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loadingAverage = false;
      }
    },
  },
});
