
import RobotGptModalHint from '@/components/analytics/yandexGPT/RobotGptModalHint.vue';
import { LicenseType } from '@/helpers/constants';
import { mapState } from 'vuex';

export default {
  name: 'NavigateToYandexGPT',
  components: {
    RobotGptModalHint,
  },
  inject: ['RouteNames'],
  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    showHint: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('profile', {
      licenses: 'licenses',
    }),
    hasHideGptLicense() {
      return this.licenses.some((name: string) => name === LicenseType.HIDE_GPT);
    },
    to() {
      return {
        name: this.RouteNames.R_APP_GPT_ANALYTICS,
        params: {
          surveyId: String(this.surveyId),
        },
        query: {
          userId: this.userId,
          tab: this.$route.query?.tab,
        },
      };
    },
    enabled() {
      return this.licenses.some((name: string) => name === LicenseType.GPT_SURVEYS);
    },
  },
  data() {
    return {
      isDialogVisible: false,
    };
  },
};
