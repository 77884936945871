
import Vue from 'vue';
import GradeValueSmile from '@/components/analytics/charts/GradeValueSmile.vue';
import getJohariMiddleValue from '@/helpers/getJohariMiddleValue';

interface GradeItem {
  value: number,
  title: string,
  name: string,
}

export default Vue.extend({
  name: 'QuestionGradeInfoCard',

  components: {
    GradeValueSmile,
  },

  props: {
    questionName: {
      type: String,
      default: '',
    },
    groupQuestionName: {
      type: String,
      default: '',
    },
    averageRating: {
      type: Number,
      default: 0,
    },
    selfRating: {
      type: Number,
      default: 0,
    },
    minRating: {
      type: Number,
      default: 1,
    },
    maxRating: {
      type: Number,
      default: 5,
    },
  },

  computed: {
    // Максимальное значение первого квадранта
    middleValue(): number {
      return getJohariMiddleValue(this.minRating, this.maxRating);
    },
    gradeList(): GradeItem[] {
      return [
        {
          value: this.averageRating,
          title: this.$t('analytics.baseCompetencies.johariGradeInfo.averageRating') as string,
          name: 'averageRating',
        },
        {
          value: this.selfRating,
          title: this.$t('analytics.baseCompetencies.johariGradeInfo.selfRating') as string,
          name: 'selfRating',
        },
      ].filter((item) => !!item.value);
    },
    description(): string {
      const hints = {
        topLeft: this.$t('analytics.baseCompetencies.johariGradeInfo.latentPotentialZone') as string,
        topRight: this.$t('analytics.baseCompetencies.johariGradeInfo.efficiencyZone') as string,
        bottomLeft: this.$t('analytics.baseCompetencies.johariGradeInfo.developmentZone') as string,
        bottomRight: this.$t('analytics.baseCompetencies.johariGradeInfo.blindZone') as string,
      };

      const y = this.averageRating <= this.middleValue ? 'bottom' : 'top';
      const x = this.selfRating <= this.middleValue ? 'Left' : 'Right';

      return hints[`${y}${x}`];
    },
  },
});
