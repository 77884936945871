
import Vue from 'vue';
import errorHandler from '@/helpers/errorHandler';
import { V1EntitiesAnalyticsFeedbackGeneralQuestionsTopItem } from '@/services/api/tsxass';
import { tsxassApi } from '@/services/api';

enum ItemType {
  STRONG = 'strong',
  WEAK = 'weak',
}

interface Grade {
  title: string;
  value: number;
}

interface Block {
  key: string;
  imageName: string;
  imageBgColor: string;
  title: string;
  description: string;
  grades: Grade[];
  gradeColor: string;
}

export default Vue.extend({
  name: 'TopResultsWidget',

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
    elevated: {
      type: Boolean,
      default: false,
    },
    imageSize: {
      type: [Number, String],
      default: 40,
    },
    showDescription: {
      type: Boolean,
      default: false,
    },
    gradeLabelClass: {
      type: String,
      default: '',
    },
    gradeTitleClass: {
      type: String,
      default: '',
    },
    blockClass: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      topItems: [] as V1EntitiesAnalyticsFeedbackGeneralQuestionsTopItem[],
      loading: false,
      // Данные были загружены хотя бы один раз
      dataIsLoaded: false,
      loadedImagesCount: 0,
    };
  },

  computed: {
    weakItems(): V1EntitiesAnalyticsFeedbackGeneralQuestionsTopItem[] {
      return this.topItems.filter((item) => item.type === ItemType.WEAK);
    },
    strongItems(): V1EntitiesAnalyticsFeedbackGeneralQuestionsTopItem[] {
      return this.topItems.filter((item) => item.type === ItemType.STRONG);
    },
    chartDataIsNotEmpty(): boolean {
      return !!this.strongItems.length || !!this.weakItems.length;
    },
    blocks(): Block[] {
      return [
        {
          key: 'strong',
          imageName: 'Rocket.png',
          imageBgColor: 'tt-light-blue',
          title: this.$t('analytics.baseCompetencies.topStrengths') as string,
          description: this.$t('analytics.baseCompetencies.topStrengthsDescription') as string,
          grades: this.strongItems,
          gradeColor: 'tt-light-green pale',
        },
        {
          key: 'weak',
          imageName: 'Compass.png',
          imageBgColor: 'tt-light-yellow',
          title: this.$t('analytics.baseCompetencies.topDevelopmentZones') as string,
          description: this.$t('analytics.baseCompetencies.topDevelopmentZonesDescription') as string,
          grades: this.weakItems,
          gradeColor: 'tt-light-yellow pale',
        },
      ].filter((block) => !!block.grades.length);
    },
    cssVars(): Record<string, any> {
      return {
        '--image-size': `${this.imageSize}px`,
      };
    },
    // Параметры запроса данных графика; используется для отслеживания изменений
    requestParams(): any[] {
      return [
        this.surveyId,
        this.userId,
      ];
    },
    // Асинхронные данные; используется для отслеживания момента готовности виджета
    asyncData(): any[] {
      return [
        this.loadedImagesCount,
        this.topItems,
        this.dataIsLoaded,
      ];
    },
    // Нет данных для отображения
    dataIsEmpty() {
      return (this.topItems as V1EntitiesAnalyticsFeedbackGeneralQuestionsTopItem[]).length === 0;
    },
  },

  watch: {
    requestParams: {
      immediate: true,
      handler() {
        this.loadChartData();
      },
    },
    asyncData: {
      immediate: true,
      async handler() {
        if (this.dataIsLoaded && this.blocks.length === this.loadedImagesCount) {
          await this.$nextTick();
          this.$emit('ready', { empty: this.dataIsEmpty });
        }
      },
    },
  },

  methods: {
    async loadChartData() {
      try {
        this.loading = true;

        const {
          data: { items },
        } = await tsxassApi.getV1AnalyticsFeedbackGeneralQuestionsTop(this.surveyId, this.userId || undefined);
        this.topItems = items || [];
      } catch (err) {
        errorHandler(err);
        this.$emit('error', err);
      } finally {
        this.loading = false;
        this.dataIsLoaded = true;
      }
    },
    onImageLoad() {
      this.loadedImagesCount += 1;
    },
  },
});
