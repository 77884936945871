
import Vue, { PropType } from 'vue';
import {
  getScoreIconByValue,
  getLargeScaleScoreIconByValue,
  getNpsScaleScoreIconByValue,
} from '@/helpers/getScoreIcon';
import { mapState } from 'vuex';
import { LicenseType } from '@/helpers/constants';

export default Vue.extend({
  name: 'HeatMapColorCellValue',

  props: {
    value: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    height: {
      type: [Number, String] as PropType<number | string>,
      default: 30,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 5,
    },
  },

  computed: {
    ...mapState('profile', {
      licenses: 'licenses',
    }),
    has10ScaleLicense(): boolean {
      return this.licenses.some((name: string) => name === LicenseType.TSXASS_360_10_SCALE);
    },
    valueIsEmpty(): boolean {
      return typeof this.value !== 'number';
    },
    buttonColor(): string | null {
      if (this.valueIsEmpty) {
        return null;
      }
      if (this.min === -100 && this.max === 100) {
        return getNpsScaleScoreIconByValue(this.value as number).color;
      }
      if (this.max > 9 && this.has10ScaleLicense) {
        return getLargeScaleScoreIconByValue(this.value as number, this.min, this.max).color;
      }
      return getScoreIconByValue(this.value as number, this.min, this.max).mutedColorCode;
    },
  },
});
