
import Vue from 'vue';
import errorHandler from '@/helpers/errorHandler';
import { V1EntitiesAnalyticsFeedbackDestructorsDynamics } from '@/services/api/tsxass';
import { tsxassApi } from '@/services/api';
import DynamicsChartWidget from './common/DynamicsChartWidget.vue';

export default Vue.extend({
  name: 'DestructorDynamics',

  components: {
    DynamicsChartWidget,
  },

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      chartData: null as V1EntitiesAnalyticsFeedbackDestructorsDynamics | null,
      loading: false,
    };
  },

  computed: {
    chartDataIsNotEmpty(): boolean {
      return !!this.chartData?.values?.length;
    },
  },

  mounted() {
    this.loadChartData();
  },

  methods: {
    async loadChartData() {
      try {
        this.loading = true;

        const { data } = await tsxassApi.getV1AnalyticsFeedbackDestructorsDynamics(
          this.surveyId,
          this.userId || undefined,
        );
        this.chartData = data;
        this.$emit('destructors-dynamics-empty', !(data.values || []).length);
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
