
import Vue, { PropType } from 'vue';
import errorHandler from '@/helpers/errorHandler';
import { tsxassApi } from '@/services/api';
import BehaviorDynamics from '@/components/analytics/widgets/BehaviorDynamics.vue';

export default Vue.extend({
  name: 'BehaviorDynamicsWrapper',

  components: {
    BehaviorDynamics,
  },

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
    groupId: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    chartScroll: {
      type: Boolean,
      default: true,
    },
    smallFont: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      chartData: {},
      chartDataLoading: false,
      answers: {},
      answersLoading: false,
    };
  },

  computed: {
    behaviorDynamicsData() {
      if (this.chartDataLoading || this.answersLoading) return [];
      const result = [];
      Object.keys(this.chartData).forEach((questionId) => {
        const chartDataItems = this.chartData[questionId];
        result.push({
          chartData: chartDataItems,
          answers: this.answers[questionId],
        });
      });
      return result;
    },
  },

  watch: {
    groupId: {
      immediate: true,
      handler() {
        if (this.answersLoading) return;
        this.loadData();
      },
    },
  },

  mounted() {
    if (this.answersLoading) return;
    this.loadData();
  },

  methods: {
    loadData() {
      this.loadChartData();
      this.loadAnswers();
    },
    async loadChartData() {
      try {
        this.chartDataLoading = true;

        const { data } = await tsxassApi.getV1AnalyticsFeedbackBehaviorDynamics(
          this.surveyId,
          this.userId || undefined,
          this.groupId || undefined,
        );
        this.chartData = this.transformChartResponse(data);
        this.$emit('behavior-dynamics-empty', !(data.values || []).length);
      } catch (err) {
        errorHandler(err);
      } finally {
        this.chartDataLoading = false;
      }
    },
    async loadAnswers() {
      try {
        this.answersLoading = true;

        const { data } = await tsxassApi.getV1AnalyticsFeedbackBehaviorAnswers(
          this.surveyId,
          this.userId || undefined,
          this.groupId || undefined,
        );

        this.answers = (data.answers || []).reduce((acc, item) => {
          if (!acc[item.questionId]) {
            acc[item.questionId] = [];
          }
          acc[item.questionId].push(item);
          return acc;
        }, {});
      } catch (err) {
        errorHandler(err);
      } finally {
        this.answersLoading = false;
      }
    },
    transformChartResponse(response) {
      const groupedByQuestionId = response.axis.reduce((acc, axis) => {
        if (!acc[axis.questionId]) {
          acc[axis.questionId] = {
            axis: [],
            legend: response.legend,
            values: [],
          };
        }
        acc[axis.questionId].axis.push(axis);
        return acc;
      }, {});

      response.values.forEach((value) => {
        const valueAxis = response.axis.find((axis) => axis.id === value.axisId);
        if (valueAxis) {
          groupedByQuestionId[valueAxis.questionId].values.push(value);
        }
      });

      return groupedByQuestionId;
    },
  },
});
